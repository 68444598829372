<script>
import api from '@/command/api'
import FormView from '../../../components/FormView'
import apiTool from '@/command/apiTool'
import { operatingCycle } from '@/utils/textFile'

export default {
  props: {
    dataForm: {
      type: Object,
      default: () => {
        return {}
      }
    },
    cascaderList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      ...api.command.getState()
    }
  },
  computed: {},
  mounted() {},
  methods: {
    getSumbitData() {
      const codeText = ['province', 'city', 'area']
      let arr = document.getElementsByClassName('ant-cascader-picker-label')[0].innerHTML.split('/')
      const obj = {}
      arr.forEach((e, i) => {
        obj[codeText[i] + 'Name'] = e
        obj[codeText[i] + 'Code'] = this.dataForm.ssq[i]
      })
      return obj
    },
    sumbit() {
      const codeData = this.getSumbitData()
      let lxfs = /^((0\d{2,3}-?\d{7,8})|(1[3465789]\d{9}))$/
      if (!lxfs.test(this.dataForm.linkPhone)) {
        this.$message.warning('请正确输入联系方式！')
        return
      }

      let obj = {
        ...this.dataForm,
        ...codeData
      }

      this.$emit('success', obj)
    },
    returnForm1() {
      const formData = [
        {
          name: '自提点名称',
          key: 'name',
          type: 'input',
          cols: 22,
          rules: [
            {
              required: true,
              type: 'string'
            }
          ]
        },
        {
          name: '位置 （例：120.184349，30.254460）',
          type: 'row',
          cols: 22,
          gutter: 5,
          rules: [
            {
              required: true
            }
          ],
          children: [
            {
              type: 'input',
              cols: 20,
              key: 'jwd',
              label: '地址',
              placeholder: '请输入经纬度以中文逗号隔开',
              rules: [
                {
                  required: true,
                  type: 'string'
                }
              ]
            },
            {
              type: 'buttonRight',
              label: '定位',
              cols: 5,
              style: {
                width: '105px',
                marginLeft: ' -5px',
                borderRadius: ' 4px',
                backgroundColor: 'rgba(44, 140, 240, 1)'
              },
              props: {
                type: 'primary',
                icon: 'environment'
              },
              onClick: () => {
                apiTool.openMap({
                  title: '打开地图',
                  value: [],
                  onSuccess: data => {
                    this.dataForm.jwd = `${data.lng + '，' + data.lat}`
                    this.dataForm.longitude = data.lng
                    this.dataForm.latitude = data.lat
                  }
                })
              }
            },
            {
              type: 'cascader',
              cols: 10,
              key: 'ssq',
              label: '地址',
              typeData: this.cascaderList,
              rules: [
                {
                  required: true,
                  type: 'array'
                }
              ]
            },
            {
              type: 'input',
              cols: 14,
              key: 'address',
              label: '地址',
              rules: [
                {
                  required: true,
                  type: 'string'
                }
              ]
            }
          ]
        },
        {
          name: '联系方式',
          key: 'linkPhone',
          type: 'input',
          cols: 22,
          rules: [
            {
              required: true,
              type: 'string'
            }
          ]
        },
        {
            type: 'timePicker',
            cols: 6,
            key: 'openTime',
            name: '营业开始时间',
            props:{
              format:"HH:mm"
            }
        },
        {
            type: 'timePicker',
            cols: 6,
            key: 'closeTime',
            name: '营业结束时间',
            props:{
              format:"HH:mm"
            }
        },
        {
            type: 'select',
            cols: 9,
            key: 'businessCycle',
            name: '营业周期',
            props:{
              mode:"multiple"
            },
            typeData:operatingCycle
        },
        {
          name: '运费(优先级高于商品运费模板)',
          key: 'freightType',
          type: 'radio',
          cols: 22,
          typeData: [
            {
              name: '免运费',
              value: '0'
            },
            {
              name: '按照商品收运费',
              value: '1'
            }
          ]
        }
      ]

      return <FormView data={formData} form={this.dataForm} />
    }
  },
  render() {
    return <div>{this.returnForm1()}</div>
  }
}
</script>
<style lang="less" scoped>
/deep/.form-input[data-v-0c5e90f3] {
  margin-top: 4px;
}
/deep/.ant-row-flex.ant-row-flex-start {
  margin-right: -40px !important;
}
</style>
